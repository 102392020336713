<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="text-center mt-2">
        <img 
          src="../../static/images/img_complete.png"
          alt="img-complete"
          />
      </div>      
      <form 
        @submit.prevent="createQuote"
        class="p-5 border border-0 rounded-lg"
        style="background-color: white;"
        >
        <div class="form-group mb-4">
            * กรุณากรอกทุกหัวข้อ และสามารถศีกษาวิธีการใช้งานโปรแกรมได้ที่
            <a href="./คู่มือการคีย์เพื่อขอเอกสารประกอบการนำเสนอขายTMB.pdf" target="_blank">
              เอกสาร
              <img src="../../static/images/pdf.png" width="25" alt="icon-pdf"/>
            </a>
            นี้
        </div>
        <div class="form-group mb-4">
          <select name="customer_type" class="form-control" v-model="form.customer_type" required readonly>
            <option value=1 selected>ชุดเอกสารใบคำขอเอาประกันภัยสำหรับลูกค้าธนาคารทหารไทยธนชาต</option>
          </select>
        </div>
        <div class="form-group mb-4">
          <input type="text" name="staff_name" class="form-control" v-model="form.staff_name" placeholder="กรุณากรอกชื่อ นามสกุล พนักงาน * ตัวอย่างเช่น สมชาย ขยันยิ่ง" required>
        </div>
        <div class="form-group mb-4">
          <input type="text" name="branch_name" class="form-control" v-model="form.branch_name" placeholder="กรุณากรอกชื่อสาขา หรือ ชื่อโซน * ตัวอย่างเช่น สาขาสำนักพหล, โซนพหลโยธิน" required>
        </div>
        <div class="form-group mb-4">
          <input type="text" maxlength="30" name="customer_id" class="form-control" v-model="form.customer_id" placeholder="กรุณากรอกรหัสลูกค้า * ตัวอย่างเช่น 001100000000000000000012345678, 0123456789" required>
        </div>
        <div class="form-group mb-4">
          <input type="text" maxlength="5" name="staff_id" class="form-control" v-model="form.staff_id" placeholder="กรุณากรอกรหัสพนักงาน * ตัวอย่างเช่น 12345" required>
        </div>
        <div class="text-center">
          <button type="submit" class="btn">
            NEXT
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "create-quote",
  components: {
    // foo-bar
  },
  created() {
    let apiURL = process.env.VUE_APP_API_CREATE_QUOTE;
    console.log(apiURL)
  },
  data() {
    return {
      form: {
        customer_type: '1',
        staff_name: '',
        branch_name: '',
        customer_id: '',
        staff_id: ''
      }
    }
  },
  methods: {
    createQuote() {
      let apiURL = process.env.VUE_APP_API_CREATE_QUOTE;
      let data = {
        customer_type: this.form.customer_type,
        staff_name: this.form.staff_name,
        branch_name: this.form.branch_name,
        customer_id: this.form.customer_id,
        staff_id: this.form.staff_id
      }
      
      axios.post(apiURL, data).then(res => {
        console.log(res)

        if (res.data) {
          this.$router.push({
            name: 'download-quote',
            params: {
              data: JSON.stringify(res.data.result.data),
              type: this.form.customer_type,
              url: res.data.result.url_download_all
            }
          })
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

button {
  background-color: #FF8901;
  color: #ffffff;
  font-weight: bold;
  width: 260px;
  height: 60px;
  font-size: 24px;
  border-radius: 40px;
}

button:hover {
  color: #ffffff;
  box-shadow: 0px 0px 16px -5px #000000;
  border-radius: 32px;
}

button:active {
  box-shadow: none;
  border-radius: 36px;
}

</style>