import Vue from 'vue'
import VueRouter from 'vue-router'
import CreateQuote from "@/components/CreateQuote.vue";
import DownloadQuote from "@/components/DownloadQuote.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "cteate-quote",
    component: CreateQuote,
  },
  {
    path: "/download",
    name: "download-quote",
    component: DownloadQuote,
    props: true
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;