<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="text-center mt-2">
        <img 
          src="../../static/images/img_complete.png"
          alt="img-complete"
          />
      </div>      
      <form
        @submit.prevent="clickDownload"
        class="p-5 border border-0 rounded-lg"
        style="background-color: white;"
        >
          <div class="text-center">
          <h5 v-if="type == 1">
            <b>
              สำหรับลูกค้าที่มีบัญชีเงินฝากธนาคารทหารไทยธนชาต
            </b>
          </h5>
          <h5 v-else>
            <b>
              สำหรับลูกค้าที่ไม่มีบัญชีเงินฝากธนาคารทหารไทยธนชาต
            </b>
          </h5>

          <h5>เอกสาร</h5>
          <ul class="text-left mt-5 mb-5">
            <li v-for="item in items" :key="item.index"> {{ item.text }} </li>
          </ul>
          <button
            type="submit"
            class="btn"
          >
            Download
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'download-quote',
  props: {
    data: String,
    type: String,
    url: String,
  },
  data() {
    return {
      items: []
    }
  },
  created(){
    if (!this.data && !this.type && !this.url) this.$router.push({name: 'cteate-quote'})
    else this.items = JSON.parse(this.data)
  },
  methods: {
    clickDownload() {
      window.location = this.url
    }
  }

}
</script>

<style scoped>

button {
  background-color: #54B192;
  color: #ffffff;
  font-weight: bold;
  width: 260px;
  height: 60px;
  font-size: 24px;
  border-radius: 40px;
}

button:hover {
  color: #ffffff;
  box-shadow: 0px 0px 16px -5px #000000;
  border-radius: 32px;
}

button:active {
  box-shadow: none;
  border-radius: 36px;
}

li::marker {
  color: #5C98B8;
}

</style>