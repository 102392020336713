<template>
  <div class="app">
    <!-- navbar -->
    <nav class="navbar navbar-light bg-white justtify-content-between flex-nowrap flex-row">
      <div class="container-fluid">
        <div class="navbar-brand flot-left" style="padding: 0">
          <img
          src="../static/images/navbar_logo.png"
          alt="navbar-logo"
          height="50"
          />
        </div>
        <div v-if="this.$route.name == 'download-quote'" class="navbar-brand flot-right">
          <router-link to="/">
            <b>
              New quote
            </b>
          </router-link>
        </div>
      </div>
    </nav>

    <!-- Router -->
    <div class="container mt-5">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

export default {

  name: 'App',
  components: {
    // foo-bar
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  color: #1d5e6d !important;
}

body {
  background: repeating-linear-gradient(#c5e9f2 , #e8f8fc 50%, #1d5e6d 50%, #78a8b4);
}

</style>
